import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { useLocation, useSearchParams, useHistory } from 'react-router-dom';

import { Image, List } from 'antd-mobile'
import { INFO_DETAIL, getInfoList, mkInnerJumpUrl } from "model/information/info"
import { sendPageJumpToApp } from 'model/msg/msg'
import { BASE_URL } from 'model/util/comm'

// -----------------------------------------------------------------------
const InformationListPage = forwardRef((props = {}, ref) => {
    let { innerPage } = props
    const [infoList, setInfoList] = useSafeState()
    const history = useHistory()

    const itemOnClickHd = (dstUrl) => {
        console.log("######: dstUrl = %o", dstUrl)
        if (innerPage) {
            let baseUrl = mkInnerJumpUrl(dstUrl)
            history.push(baseUrl)
        }else{
            sendPageJumpToApp(dstUrl)
        }
    }

    useMount(async () => {
        const tmpList = await getInfoList()
        if (tmpList) {
            setInfoList(tmpList)
        }
    })

    const getLeftDisplay = () => {
        if (infoList) {
            let content = infoList.map((value, index) => 
                <List.Item onClick={() => itemOnClickHd(value?.AccessUrl)} clickable={false} key={index}
                    prefix={<ItemPrefix picSrc={value?.TitlePicUrl}/>}
                    description={value?.Description}
                >{value?.Title}</List.Item>
            )

            return content
        }

        return undefined
    }

    return (
        <>
            <List >
                {getLeftDisplay()}

                {/* <List.Item onClick={() => itemOnClickHd(INFO_DETAIL.PAGE_1.PAGEURL)} clickable={false}
                    prefix={<ItemPrefix picSrc={INFO_DETAIL.PAGE_1.PICURL}/>}
                    description={INFO_DETAIL.PAGE_1.DESC}
                >{INFO_DETAIL.PAGE_1.TITLE}</List.Item>

                <List.Item onClick={() => itemOnClickHd(INFO_DETAIL.PAGE_2.PAGEURL)} clickable={false}
                    prefix={<ItemPrefix picSrc={INFO_DETAIL.PAGE_2.PICURL}/>}
                    description={INFO_DETAIL.PAGE_2.DESC}
                >{INFO_DETAIL.PAGE_2.TITLE}</List.Item>

                <List.Item onClick={() => itemOnClickHd(INFO_DETAIL.PAGE_3.PAGEURL)} clickable={false}
                    prefix={<ItemPrefix picSrc={INFO_DETAIL.PAGE_3.PICURL}/>}
                    description={INFO_DETAIL.PAGE_3.DESC}
                >{INFO_DETAIL.PAGE_3.TITLE}</List.Item>

                <List.Item onClick={() => itemOnClickHd(INFO_DETAIL.PAGE_4.PAGEURL)} clickable={false}
                    prefix={<ItemPrefix picSrc={INFO_DETAIL.PAGE_4.PICURL}/>}
                    description={INFO_DETAIL.PAGE_4.DESC}
                >{INFO_DETAIL.PAGE_4.TITLE}</List.Item> */}

            </List>
        </>
    )
})

const ItemPrefix = forwardRef((props = {}, ref) => {
    let { picSrc } = props

    return (
        <>
            <div className='row_space_small' />
            <Image src={picSrc} style={{ borderRadius: 8 }} fit='cover'  width={120} />
            <div className='row_space_small' />
        </>
    )
})






// -----------------------------------------------------------------------
export { InformationListPage, ItemPrefix }

