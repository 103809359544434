import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { Image, Space, AutoCenter } from 'antd-mobile'

// -------------------------------------------------------------------------------------------
const ImageDesc = forwardRef((props = {}, ref) => {
    let { picSrc, descText, width, height} = props

    return (
        <>
            <div className='row_center'>
                <Image src={picSrc} width={width} height={height}/>
            </div>
            <div className='row_space_small'/>
            <div className='row_left'>
                {descText}
            </div>
        </>
    )
})

const AOBCourse = forwardRef((props = {}, ref) => {

    return (
        <>
        <div className='courseTitle'>图文操作步骤</div>
        <div className='courseTitle2'>样本采集</div>
        <ImageDesc picSrc={process.env.PUBLIC_URL + "/images/course/m1.png"} width={200} height={172} 
        descText="1.让唾液在口中停留汇集至少30秒"
        />

        <div className='row_space_middle'/>
        <ImageDesc picSrc={process.env.PUBLIC_URL + "/images/course/m2.png"} width={200} height={172} 
        descText="2.打开采样拭子包装，将唾液采样拭子放在舌下，含在口中90秒左右。收集唾液时，可以将拭子头在口中左右滚动几下，以增加唾液的吸收"
        />

        <div className='row_space_middle'/>
        <div className='courseTitle2'>检验方法</div>
        <ImageDesc picSrc={process.env.PUBLIC_URL + "/images/course/a1.png"} width={200} height={200} 
        descText="1.撕开铝箔袋，取出检测卡"
        />

        <div className='row_space_middle'/>
        <ImageDesc picSrc={process.env.PUBLIC_URL + "/images/course/a2.png"} width={64} height={200} 
        descText="2.将采样后的拭子头插入检测卡的加样孔中至底部"
        />

        <div className='row_space_middle'/>
        <ImageDesc picSrc={process.env.PUBLIC_URL + "/images/course/a3.png"} width={140} height={200} 
        descText="3.握住采样拭子的手柄端上下反复挤压3-5次，将拭子头里的唾液挤出"
        />

        <div className='row_space_middle'/>
        <ImageDesc picSrc={process.env.PUBLIC_URL + "/images/course/a4.png"} width={210} height={50} 
        descText="4.待样本移行至观察窗口后，平放检测卡；5-10分钟观察结果"
        />

        <div className='row_space_middle'/>
        <div className='courseTitle2'>检验结果解释</div>
        <div className="row_left">O型：仅质控区（C）出现一条红色条带，在检测区（T1，T2）均无条带出现。</div>
        <div className='row_space_small'/>
        <div className="row_left">A型：质控区（C）和在检测区（T1）各出现一条红色条带。</div>
        <div className='row_space_small'/>
        <div className="row_left">B型：质控区（C）和在检测区（T2）各出现一条红色条带。</div>
        <div className='row_space_small'/>
        <div className="row_left">AB型：质控区（C）出现一条红色条带，在检测区（T1，T2）也出现两条红色条带。</div>
        

        <div className='row_space_big'/>
        
        </>
    )
})

const CortisolCourse = forwardRef((props = {}, ref) => {

    return (
        <>
            <div className='courseTitle'>教程开发中，敬请期待</div>
        </>
    )
})


// -------------------------------------------------------------------------------------------
export { AOBCourse, CortisolCourse }



