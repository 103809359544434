import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { useLocation, useSearchParams, useHistory, useParams } from 'react-router-dom';
import { qryCourse } from 'model/course/course'
import { mkInfoShareUrl, getWebConfigFromUuid } from "model/information/info"
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { sendBackToApp, requestTokenFromApp, sendSharedToApp } from 'model/msg/msg'
import { getResultUrlIsShared } from "model/recognition/recognition"
import { BackIcon, ShareIcon } from 'component/icons'

// ----------------------------------------------------------------------------------
const CourseDetailPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const isShare = getResultUrlIsShared(searchParams)
    let { uuid } = useParams() 
    if (uuid.length > 32) {
        uuid = uuid.substring(0, 32)
    }

    let detailRef = useRef()

    const shareOnClickHd = () => {
        if (detailRef?.current) {
            const courseInfo = detailRef?.current?.getCourseInfo()
            if (courseInfo) {
                const shareUrl = mkInfoShareUrl(courseInfo?.ShareUrl)
                sendSharedToApp(shareUrl, courseInfo?.Title, courseInfo?.Description)
            }
        }
    }

    const getTitleContent = () => {
        if (isShare) {
            return undefined
        }

        return (
            <div className='startLine'>
                <div className='row_right'>
                    <div onClick={shareOnClickHd} style={{marginRight: "16px"}}>
                        <ShareIcon style={{fontSize: "20px"}}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {getTitleContent()}
            <CourseDetailContent uuid={uuid} ref={detailRef}/>
        </>
    )
})

const CourseDetailContent = forwardRef((props = {}, ref) => {
    let { uuid } = props

    const [rawData, setRawData] = useSafeState()
    let courseRef = useRef()

    const getContent = () => {
        let tmp = BraftEditor.createEditorState(rawData)
        return tmp.toHTML()
    }

    useMount(async () => {
        const data = await getWebConfigFromUuid(uuid)
        if (data) {
            setRawData(data)
        }

        const tmpInfo = await qryCourse(uuid)
        if (tmpInfo) {
            courseRef.current = tmpInfo
            console.log("$$$$$$---> tmpInfo = %o", tmpInfo)
        }
    })

    let getCourseInfo = useMemoizedFn(() => {
        return courseRef?.current
    }, [])

    useImperativeHandle(ref, () => ({
        getCourseInfo
    }), [getCourseInfo]);

    return (
        <>
            <div className="braft-output-content" dangerouslySetInnerHTML={{__html: getContent()}} />
        </>
    )
})


// ----------------------------------------------------------------------------------
export { CourseDetailPage, CourseDetailContent }

