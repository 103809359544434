/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */

import JSEncrypt from "jsencrypt";
import { customAlphabet } from 'nanoid/non-secure';

// --------------------------------------------------------------------------------------------------------
const nanoString = customAlphabet('ABCDEFGHJKMNPQRSTWXYZ2345678', 10)
const nanoNumber = customAlphabet('0123456789', 10)

// 使用命令生成公钥、私钥
// openssl genrsa -out rsa_private_key.pem 1024
// openssl rsa -in rsa_private_key.pem -pubout -out rsa_public_key.pem
// 公钥
const PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC7qY7rpCBeN18I0GwuHQZFGVxOB+nw6AwokOhU5+t5EHwWEfN6hWmvA7NRNFH85ncSS3+Z25gk878It3cD1dI0Wgh1iR1nJt0n38JQAGArsGR3a4VkhRVEuz5ygjq6DzWxJcGpqiQsmCr41oFiCbwZjhn+NyyjahuJzzUdlGmukwIDAQAB-----END PUBLIC KEY-----';

// --------------------------------------------------------------------------------------------------------
export function encrypt(str){
    //使用公钥加密
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encrypt.encrypt(str);
}

export function decrypt(str){
    //使用公钥解密
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encrypt.decrypt(str);
}

export function randomString(len) {
    return nanoString(len);
}

export function randomNumber(len) {
    return nanoNumber(len);
}

export function randomFind(array, compFun) {
    let newArray = []
    array.forEach(element => {
        if (compFun(element)) {
            newArray.push(element)
        }
    });
    return newArray[Math.floor(Math.random() * newArray.length)];
}

export const hh_log = (content) => {
    console.log(content)
}

const getWeekDayStr = (weedDay) => {
    let result = ""
    if (weedDay === 0) {
        result = "星期天"
    }else if (weedDay === 1) {
        result = "星期一"
    }else if (weedDay === 2) {
        result = "星期二"
    }else if (weedDay === 3) {
        result = "星期三"
    }else if (weedDay === 4) {
        result = "星期四"
    }else if (weedDay === 5) {
        result = "星期五"
    }else if (weedDay === 6) {
        result = "星期六"
    }
    return result
}

export const getDisplayWeekTime = () => {
    const date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    if (month < 10) {
        month = `${month}`
    }
    let day = date.getDate()
    if (day < 10) {
        day = `${day}`
    }
    
    let weekDay = date.getDay()
    weekDay = getWeekDayStr(weekDay)

    let result = year + '年' + month + '月' + day + '日' + ' ' + weekDay
    
    return result
}

export const getDisplayTime = () => {
    const date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    if (month < 10) {
        month = `${month}`
    }
    let day = date.getDate()
    if (day < 10) {
        day = `${day}`
    }
    
    let hour = date.getHours();
    if (hour < 10) {
        hour = `0${hour}`
    }

    let min = date.getMinutes();
    if (min < 10) {
        min = `0${min}`
    }

    let result = year + '年' + month + '月' + day + '日' + ' ' + hour + ":" + min
    
    return result
}

export const getFixDisplayTime = (fixedTime) => {
    // fixedTime = "2024-06-06 20:25:28"

    let ttt = Date.parse(fixedTime)
    if (ttt) {
        console.log("###$$$: ttt = %o, OK", ttt)
    }else{
        console.log("###$$$: ttt = %o, NO OK", ttt)
        return undefined
    }
    const date = new Date(fixedTime);

    let year = date.getFullYear();

    let month = date.getMonth() + 1;
    if (month < 10) {
        month = `0${month}`
    }
    let day = date.getDate();
    if (day < 10) {
        day = `0${day}`
    }
    let hour = date.getHours();
    if (hour < 10) {
        hour = `0${hour}`
    }
    let min = date.getMinutes();
    if (min < 10) {
        min = `0${min}`
    }

    let result = year + '年' + month + '月' + day + '日' + ' ' + hour + ":" + min
    
    return result
}

export const findInList = (item, srcList) => {
    for (let record of srcList) {
        if (record === item) {
            return true
        }
    }
    return false
}

// mntn 是可以存储回调关系的存储对象，包括notfiyMap子对象
export const eventReg = (mntn, key, eventList, notifyCb) => {
    let eventInfo = {
        eventList: eventList, 
        notifyCb: notifyCb, 
    }
    mntn.notfiyMap[key] = eventInfo
}

export const eventUnreg = (mntn, key) => {
    delete mntn.notfiyMap[key]
}

export const eventPublish = (mntn, event, data) => {
    for (let key in mntn.notfiyMap) {
        let item = mntn.notfiyMap[key]
        // 查找是否有对应的event
        let find = findInList(event, item.eventList)
        if (find) {
            // 调用，这里起个定时器调用，防止应用函数挂死逻辑处理
            setTimeout(() => {
                item.notifyCb(event, data)
            }, 1)
        }
    }
}
