import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { useLocation, useSearchParams, useHistory } from 'react-router-dom';

import { Grid, Button, Space, Modal, Radio, Tabs, Image, SwipeAction } from 'antd-mobile'
import { COLOR } from 'component/comm'
import { BackupTitle, LoadingHint } from 'pages/utils/utils'
import { userEventReg, userEventUnreg, USER_EVENT, setUserToken, getUserToken } from 'model/user/user'
import { requestTokenFromApp, sendBackToApp } from 'model/msg/msg'
import { RECOGN_EVENT, recognEventReg, recognEventUnreg, recognHistoryRequest, getRecognHistoryList, 
    mkHistoryDetailUrl, mkLatestDetailUrl, getUrlIsUnderApproval, delResultRequest } from 'model/recognition/recognition'
import { PRODUCT_TYPE } from 'model/product/product'
import { UNDER_APPROVAL } from 'model/util/comm'

const PAGE = "page-recognition-history"
// -----------------------------------------------------------------------
const NoResultBody = forwardRef((props = {}, ref) => {
    return (
        <>
            <div className='row_space_big' />
            <div className='row_space_big' />
            <div className='row_center'>
                <span style={{fontSize: "24px"}}>暂无记录</span>
            </div>
            <div className='row_space_small' />
            <div className='resultBG2'>
                <div className='pic' >
                    <Image src={process.env.PUBLIC_URL + "/images/noResult.png"} />
                </div>
            </div>
        </>
    )
})


const ResultCard = forwardRef((props = {}, ref) => {
    let { ProductType, RecordId, OpTime, DisplayTitle, ProductTitle, ResultDisplayUrl, deleteHd } = props
    const history = useHistory()
    let swipeRef = useRef()

    const getIconUrl = (tmpType) => {
        if (tmpType === PRODUCT_TYPE.AOB) {
            return process.env.PUBLIC_URL + "/images/AOB.png"
        }else if (tmpType === PRODUCT_TYPE.CORTISOL) {
            return process.env.PUBLIC_URL + "/images/Cortisol.png"
        }
    }

    const getDisplayTitle = (title) => {
        if (title) {
            if (title === "") {
                return "未识别到试纸"
            }
            return title
        }else{
            return "未识别到试纸"
        }
    }

    const detailOnClickHd = () => {
        const dstUrl = mkHistoryDetailUrl(ResultDisplayUrl, OpTime)
        if (dstUrl) {
            history.push(dstUrl)
        }
    }

    const deleteOnClick = () => {
        if (deleteHd) {
            deleteHd(RecordId)
        }
        if(swipeRef.current){
            swipeRef.current.close()
        }
    }

    return (
        <>
            <div style={{marginLeft: "16px", marginRight: "16px"}}>
                <div className='row_left'>
                    <span style={{color: COLOR.GRAY_6}}>{OpTime}</span>
                </div>
                <div className="row_space_small"/>
                <SwipeAction 
                    ref={swipeRef}
                    closeOnAction={false}
                    closeOnTouchOutside={false}
                    rightActions={[
                        {
                            key: "delete", 
                            text: "删除",
                            color: 'danger', 
                            onClick: deleteOnClick, 
                        },
                    ]}
                >
                <div className='historyContent' onClick={detailOnClickHd}>
                    <div style={{display: "flex", justifyContent: "start", height: "60px", alignItems: 'center'}}>
                    <Image src={getIconUrl(ProductType)} width={32} height={32} />
                    <span style={{fontSize: "18px", fontWeight: 500, marginLeft: "8px"}}>{getDisplayTitle(DisplayTitle)}</span>
                        <div style={{display: "flex", justifyContent: "flex-end", alignItems: 'center', flex: 1, marginRight: "16px"}}>
                            <span >{ProductTitle}</span>
                        </div>
                    </div>
                </div>
                </SwipeAction>
            </div>
            <div className="row_space_middle"/>
        </>
    )
})

const HistoryListContent = forwardRef((props = {}, ref) => {
    let { historyList, underApproval } = props

    const produceTitleMap = (productType, productTitle) => {
        if (!underApproval) {
            return productTitle
        }

        if (productType === PRODUCT_TYPE.AOB) {
            return "ABO性格"
        }else if (productType === PRODUCT_TYPE.CORTISOL) {
            return "Cortisol值"
        }

        return productTitle
    }

    const titleMap = (displayTitle) => {
        if (!underApproval) {
            return displayTitle
        }

        if (displayTitle === "零压力") {
            return "愉悦度高"
        }else if (displayTitle === "中度压力") {
            return "愉悦度中"
        }else if (displayTitle === "重度压力"){
            return "愉悦度低"
        }else{
            return displayTitle
        }
    }

    const deleteHd = (recordId) => {
        delResultRequest(underApproval, recordId)
    }

    let displayList = historyList.map((value, index) => {
        return (<ResultCard ProductType={value?.ProductType} 
            RecordId={value?.Id}
            OpTime={value?.OpTime}
            DisplayTitle={titleMap(value?.DisplayTitle)}
            ResultDisplayUrl={value?.ResultDisplayUrl}
            ProductTitle={produceTitleMap(value?.ProductType, value?.ProductTitle)}
            key={index}
            deleteHd={deleteHd}
        />)
    })

    return (
        <>
            <div className="row_space_big"/>
            {displayList}
        </>
    )

})

const ResultHistoryPage = forwardRef((props = {}, ref) => {
    const [historyList, setHistoryList] = useSafeState(getRecognHistoryList())
    const [loading, setLoading] = useSafeState(true)
    const forceUpdate = useUpdate()
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search);
    const underApproval = getUrlIsUnderApproval(searchParams)

    const tokenEventHd = (event, msg) => {
        if (event === USER_EVENT.TOKEN_CHANGED) {
            // 获取数据
            recognHistoryRequest(underApproval)
        }
    }

    const backOnClickHd = () => {
        sendBackToApp("/result/history")
    }

    const historyChangedHd = (event, msg) => {
        if (event === RECOGN_EVENT.HISTORY_CHANGED) {
            setHistoryList(msg)
            console.log("@@@@@: msg = %o", msg)
            forceUpdate()
        }
    }

    useMount(() => {
        const token = getUserToken()
        if (token === "") {
            // 请求token
            requestTokenFromApp("/result/history")
        }else{
            // 获取数据
            recognHistoryRequest(underApproval)
        }

        // 订阅token改变事件
        userEventReg(`${PAGE}-history`, [USER_EVENT.TOKEN_CHANGED], tokenEventHd)
        recognEventReg(`${PAGE}-history`, [RECOGN_EVENT.HISTORY_CHANGED], historyChangedHd)
        setTimeout(() => {
            setLoading(false)
        }, 3500)
    })

    // const reloadHd = () => {
    //     recognHistoryRequest(underApproval)
    // }

    useUnmount(() => {
        userEventUnreg(`${PAGE}-history`)
        recognEventUnreg(`${PAGE}-history`)
    })

    const getDisplayHistory = () => {
        if (historyList?.length > 0) {
            // 有结果
            return (
                <HistoryListContent historyList={historyList} underApproval={underApproval} />
            )
        }
        
        if (loading) {
            return (
                <LoadingHint />
            )
        }

        // 无结果
        return (
            <NoResultBody />
        )
    }

    return (
        <>
            <BackupTitle title="检测历史记录" noShare={true} backOnClickHd={backOnClickHd}/>
            {getDisplayHistory()}
        </>
    )
})

const ResultLatestPage = forwardRef((props = {}, ref) => {
    const [loading, setLoading] = useSafeState(true)
    const history = useHistory()
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search);
    const underApproval = getUrlIsUnderApproval(searchParams)

    const tokenEventHd = (event, msg) => {
        if (event === USER_EVENT.TOKEN_CHANGED) {
            // 获取数据
            recognHistoryRequest(underApproval)
        }
    }

    const historyChangedHd = (event, msg) => {
        if (event === RECOGN_EVENT.HISTORY_CHANGED) {
            if (msg?.length > 0) {
                const dstUrl = mkLatestDetailUrl(msg[0]?.ResultDisplayUrl, msg[0]?.OpTime)
                history.push(dstUrl)
            }
        }
    }

    useMount(() => {
        const token = getUserToken()
        if (token === "") {
            // 请求token
            requestTokenFromApp("/result/history")
        }else{
            // 获取数据
            recognHistoryRequest(underApproval)
        }
        // 订阅token改变事件
        userEventReg(`${PAGE}-latest`, [USER_EVENT.TOKEN_CHANGED], tokenEventHd)
        recognEventReg(`${PAGE}-latest`, [RECOGN_EVENT.HISTORY_CHANGED], historyChangedHd)

        const tmpList = getRecognHistoryList()
        if (tmpList?.length > 0) {
            const dstUrl = mkLatestDetailUrl(tmpList[0]?.ResultDisplayUrl, tmpList[0]?.OpTime)
            history.push(dstUrl)
        }

        setTimeout(() => {
            setLoading(false)
        }, 3500)
    })

    useUnmount(() => {
        userEventUnreg(`${PAGE}-latest`)
        recognEventUnreg(`${PAGE}-latest`)
    })

    const getDisplayContent = () => {
        if (loading) {
            return (
                <LoadingHint />
            )
        }

        return (
            <NoResultBody />
        )
    }

    return (
        <>
            {getDisplayContent()}
        </>
    )


})


const NoDataPage = forwardRef((props = {}, ref) => {
    const history = useHistory()

    const backOnClickHd = () => {
        sendBackToApp("/profile/collection")
        // history.goBack()
    }

    return (
        <>
            <BackupTitle title="收藏列表" noShare={true} backOnClickHd={backOnClickHd}/>
            <NoResultBody />
        </>
    )
})




// -----------------------------------------------------------------------
export { ResultHistoryPage, ResultLatestPage, NoDataPage }


