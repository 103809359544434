


// --------------------------------------------------------------------------------------------------------
let SHARE_TITLE = {
    AOB: "AOB", 
    CORTISOL: "Cortisol", 
    LIST: [
        {value: "AOB", label: "我的血型性格"}, 
        {value: "Cortisol", label: "我最近的压力"}, 
    ], 
    description: (value) => SHARE_TITLE.LIST.find(l => l.value === value)?.label,
}

let BASE_URL = {
    ONLINE: "https://hhmobile.angliancd.com", 
}


const  UNDER_APPROVAL = false


// --------------------------------------------------------------------------------------------------------
export { SHARE_TITLE, BASE_URL, UNDER_APPROVAL }



