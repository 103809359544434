import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { useLocation, useSearchParams, useHistory } from 'react-router-dom';
import { Grid, Button, Space, Modal, Radio, Tabs, List, CapsuleTabs, SwipeAction } from 'antd-mobile'

import { userCollectList } from 'model/user/user'
import { requestTokenFromApp, sendBackToApp } from 'model/msg/msg'
import { BackupTitle, LoadingHint } from 'pages/utils/utils'
import { setCollectTabKey, getCollectTabKey } from 'model/course/course'
import { INFO_DETAIL, getInfoList, mkInnerJumpUrl } from "model/information/info"
import { ItemPrefix } from 'pages/information/list'
import { CollectCard } from 'pages/course/course'
import { CourseDetailContent } from 'pages/course/detial'

// -----------------------------------------------------------------------
const CollectsPage = forwardRef((props = {}, ref) => {
    const [activeTab, setActiveTab] = useSafeState(getCollectTabKey())
    const [infoList, setInfoList] = useSafeState([])
    const [courseList, setCourseList] = useSafeState([])

    const mkTitle = (title) => {
        return (
            <span style={{fontSize: "20px", fontWeight: 500}}>{title}</span>
        )
    }

    const tabChangeHd = (key) => {
        setCollectTabKey(key)
        setActiveTab(key)
    }

    useMount(async () => {
        const collectInfo = await userCollectList()
        if (collectInfo) {
            setInfoList(collectInfo?.InfoList)
            setCourseList(collectInfo?.CourseList)
        }
    })

    const backOnClickHd = () => {
        sendBackToApp("/profile/collection")
    }

    return (
        <>
            <BackupTitle title="收藏列表" noShare={true} backOnClickHd={backOnClickHd}/>
            <div className='ho-tabs'>
            <Tabs stretch={false}
                onChange={tabChangeHd}
                activeKey={activeTab}
                activeLineMode='fixed'
                style={{
                    '--fixed-active-line-width': '24px',
                    '--active-line-height': '6px', 
                    }}
            >
                <Tabs.Tab title={mkTitle('资讯')} key='info' >
                    <CollectInfoList infoList={infoList} />
                </Tabs.Tab>
                <Tabs.Tab title={mkTitle('教程')} key='course' >
                    <CollectCourseList courseList={courseList}/>
                </Tabs.Tab>
            </Tabs>
            </div>
        </>
    )
})

const CollectInfoList = forwardRef((props = {}, ref) => {
    let { infoList } = props
    const history = useHistory()

    const itemOnClickHd = (dstUrl) => {
        console.log("######: dstUrl = %o", dstUrl)
        let baseUrl = mkInnerJumpUrl(dstUrl)
        history.push(baseUrl)
    }

    const getLeftDisplay = () => {
        if (infoList?.length > 0) {
            let content = infoList.map((value, index) => 
                <List.Item onClick={() => itemOnClickHd(value?.AccessUrl)} clickable={false} key={index}
                    prefix={<ItemPrefix picSrc={value?.TitlePicUrl}/>}
                    description={value?.Description}
                >{value?.Title}</List.Item>
            )

            return content
        }

        return undefined
    }

    return (
        <>
            <List >
                {getLeftDisplay()}
            </List>
        </>
    )
})

const CollectCourseList = forwardRef((props = {}, ref) => {
    let { courseList } = props
    const [activeTab, setActiveTab] = useSafeState(undefined)

    const tabOnchangeHd = (key) => {
        console.log("@@@@@@@: key = %o", key)
        setActiveTab(key)
    }

    useMount(async () => {
        if (courseList?.length > 0) {
            setActiveTab(courseList[0]?.Uuid)
        }
    })

    const getTabContent = () => {
        if (courseList?.length > 0) {
            let displayContent = courseList?.map((value, index) => {
                return (
                    <CapsuleTabs.Tab title={value?.DisplayCategory} key={value?.Uuid}>
                        <CollectCard uuid={value?.Uuid}/>
                        <CourseDetailContent uuid={value?.Uuid} key={index}/>
                    </CapsuleTabs.Tab>
                )
            })

            return displayContent
        }

        return undefined
    }

    return (
        <>  
            <CapsuleTabs activeKey={activeTab} onChange={tabOnchangeHd}>
                {getTabContent()}
            </CapsuleTabs>
        </>
    )
})


// -----------------------------------------------------------------------
export { CollectsPage }

