import React, { RefObject, forwardRef, useEffect } from 'react'

import { useSafeState } from 'ahooks';

// ----------------------------------------------------------------------------------
const COLOR = {
    FONT_DEFAULT: "#d9d9d9",
    ICON_DEFAULT: "#5465CF", 
    PRIMARY: "#01C288", 
    RED_6: "#f5222d", 
    RED_PRESS: "#ff5733", 
    VOLCANO_6: "#fa541c", 
    VOLCANO_8: "#ad2102", 
    ORANGE_6: "#fa8c16", 
    ORANGE_8: "#ad4e00", 
    
    GOLD_6: "#faad14", 
    GOLD_3: "#ffe58f", 
    GOLD_PRESS: "#ffc300", 
    YELLOW_6: "#fadb14", 
    YELLOW_3: "#fffb8f", 
    LIME_6: "#a0d911", 

    GREEN_5: "#73d13d", 
    GREEN_6: "#52c41a", 
    GREEN_7: "#389e0d", 
    CYAN_6: "#13c2c2",
    CYAN_7: "#08979c",
    BLUE_6: "#1677ff", 
    
    GRAY_1: "#ffffff", 
    GRAY_5: "#d9d9d9", 
    GRAY_6: "#bfbfbf", 
    GRAY_7: "#8c8c8c", 
    GRAY_8: "#595959", 
    GRAY_13: "#000000", 
    GRAY_RESULT: "#CCCCCC", 

    

    GEEKBLUE_6: "#2f54eb", 
    GEEKBLUE_10: "#030852", 
    PURPLE_5: "#9254de", 
    PURPLE_6: "#722ed1", 
    PURPLE_7: "#531dab", 
    MAGENTA_6: "#eb2f96", 

    
}

// ----------------------------------------------------------------------------------

const HideSwitch = forwardRef((props = {}, ref) => {
    // 点击间隔时间在1s内，连点5次认为值发生改变
    let { initValue, valueChangeHd } = props

    let tmpInitValue = false
    if (initValue) {
        tmpInitValue = initValue
    }

    const limitTimes = 5
    const [switchValue, setSwitchValue] = useSafeState(tmpInitValue)
    const [clickTime, setClickTime] = useSafeState()
    const [clickTimes, setClickTimes] = useSafeState(0)

    const onClickHd = () => {
        let now = Date.now()
        let dstChickTimes = clickTimes
        if (clickTimes === 0) {
            dstChickTimes += 1
        }else{
            if (now - clickTime > 1000) {
                dstChickTimes = 0
            }else{
                dstChickTimes += 1
            }
        }

        if (dstChickTimes >= limitTimes) {
            // 需要通知调用层，隐藏开关已经切换
            dstChickTimes = 0
            if (valueChangeHd) {
                valueChangeHd(!switchValue)
            }
            setSwitchValue(!switchValue)
        }

        setClickTimes(dstChickTimes)
        setClickTime(now)
    }

    return (
        <div onClick={onClickHd}>
            {props.children}
        </div>
    )

})

const VideoOverlay = forwardRef((props = {}, ref) => {
    let { hintText } = props

    return (
        <div style={{position: 'relative'}}>
            <div className='mask_content'>
                <div className='left' />
                <div className='center' style={{width: "70%"}}>
                    <div className='one' style={{height: "25%"}} />
                    <div className='two' style={{height: "25%"}} />
                    <div className='three' style={{height: "50%"}}>
                        <div style={{fontSize: "1.2em", color: "white", marginTop: "8px"}}>{hintText}</div>
                    </div>
                </div>
                <div className='right' />
            </div>
            {props.children}
        </div>
    )
})

const ExternalPage = forwardRef((props = {}, ref) => {
    let { pageUrl } = props

    const [content, setContent] = useSafeState('');

    useEffect(() => {
        const fetchContent = async () => {
            try {
            const response = await fetch(pageUrl);
            const text = await response.text();
            setContent(text);
            } catch (error) {
                console.error('Error fetching external content:', error);
            }
        }
        fetchContent()
    }, [pageUrl])

    return (
        <>
        <div dangerouslySetInnerHTML={{__html: content}} />
        </>
    )
})


const ExternalPage2 = forwardRef((props = {}, ref) => {
    let { pageUrl } = props

    return (
        <div style={{height: "100vh"}}>
            <iframe className='iframe'
        src={pageUrl}
        // style={{ border: 'none' }}
        title="External Page"
        width='100%'
        height="100%"
        // scrolling="no"
      />
        </div>
    )
})




// ----------------------------------------------------------------------------------
export { HideSwitch, VideoOverlay, ExternalPage, ExternalPage2 }
export { COLOR }


