
let MSG_TYPE = {
    WEB_BACK: "webBack", 
    WEB_REQUEST_TOKEN: "webRequstToken", 
    WEB_JUMP_PAGE: "webJumpPage", 
    WEB_SHARE_PAGE: "webSharePage", 
}

let RSP_STATUS = {
    SUCCESS: 0, 
    UNKNOWN: 100000, 
    TOKEN_MISS: 100007, 
    TOKEN_CHECK_FAILED: 100008, 
    TOKEN_EXPIRED: 100009, 
    
}


// ------------------------------------------------------------------------------------
const sendMsgToIos = (msg) => {
    console.log("@@ sendMsgToIos: msg = %s", msg)
    if (window?.webkit?.messageHandlers?.callbackHandler?.postMessage) {
        window.webkit.messageHandlers.callbackHandler.postMessage(msg);
    }else{
        console.log("sendMsgToIos: not ios sys!")
    }
}

const sendMsgToAndroid = (msg) => {
    console.log("@@ sendMsgToAndroid: msg = %s", msg)
    if (window?.Android?.sentMessage) {
        window.Android.sentMessage(msg);
    }else{
        console.log("sendMsgToAndroid: not android sys!")
    }
}

const sendMsgToApp = (msg) => {
    sendMsgToIos(msg)
    sendMsgToAndroid(msg)
}


// ------------------------------------------------------------------------------------
const sendBackToApp = (currentUrl) => {
    let msg = {
        Action: MSG_TYPE.WEB_BACK, 
        Body: {
            CurrentUrl: currentUrl, 
        }, 
    }

    // 转为json字符串
    let message = JSON.stringify(msg)
    sendMsgToApp(message)
}

const requestTokenFromApp = (currentUrl) => {
    let msg = {
        Action: MSG_TYPE.WEB_REQUEST_TOKEN, 
        Body: {
            CurrentUrl: currentUrl, 
        }, 
    }

    // 转为json字符串
    let message = JSON.stringify(msg)
    sendMsgToApp(message)
}

const sendPageJumpToApp = (dstUrl) => {
    let msg = {
        Action: MSG_TYPE.WEB_JUMP_PAGE, 
        Body: {
            DstUrl: dstUrl, 
        }, 
    }
    // 转为json字符串
    let message = JSON.stringify(msg)
    sendMsgToApp(message)
}

const sendSharedToApp = (currentUrl, title, description) => {
    let msg = {
        Action: MSG_TYPE.WEB_SHARE_PAGE, 
        Body: {
            ShareUrl: currentUrl, 
            Title: title, 
            Description: description, 
        }, 
    }
    // 转为json字符串
    let message = JSON.stringify(msg)
    sendMsgToApp(message)
}




// ------------------------------------------------------------------------------------
export { sendBackToApp, requestTokenFromApp, sendPageJumpToApp, sendSharedToApp }
export { RSP_STATUS }
