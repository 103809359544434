import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { useLocation, useSearchParams, useHistory, useParams } from 'react-router-dom';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { ExternalPage, ExternalPage2 } from 'component/comm'
import { DotLoading } from 'antd-mobile'
import { INFO_DETAIL, mkInfoShareUrl, getWebConfigFromUuid, qryInfo, getUrlIsInnerJump, 
    infoCollectQry, infoCollect, infoCollectCancel } from "model/information/info"
import { BackIcon } from 'component/icons'
import { BackupTitle } from 'pages/utils/utils'
import { sendBackToApp, requestTokenFromApp, sendSharedToApp } from 'model/msg/msg'
import { mkResultShareUrl, getResultUrlHasTitle, getResultUrlIsShared, getResultUrlOptime } from "model/recognition/recognition"

// ----------------------------------------------------------------------------------
const DetailPage = forwardRef((props = {}, ref) => {

    const backOnClickHd = () => {

    }

    return (
        <>
            <div className='startLine'>
                <div className='row_left'>
                    <div onClick={backOnClickHd}>
                        <BackIcon style={{fontSize: "24px"}} />
                    </div>
                    <span style={{marginLeft: "8px", fontSize: "20px", fontWeight: 500}}>返回</span>
                </div>
            </div>
            <div style={{width: "100%"}}>
            {/* <DotLoading > */}
            <ExternalPage2 pageUrl={INFO_DETAIL.PAGE_4.LINKURL} />
            {/* </DotLoading> */}
            </div>
            
        </>
    )
})

const Detail = forwardRef((props = {}, ref) => {
    let { pageUrl, pageLink, noShare, shareOnClickHd, isShare } = props

    const backOnClickHd = () => {
        sendBackToApp(pageUrl)
    }

    const getTitleContent = () => {
        if (isShare) {
            return undefined
        }

        return (
            <div className='startLine'>
                <BackupTitle  backOnClickHd={backOnClickHd} shareOnClickHd={shareOnClickHd} noShare={noShare}/>
            </div>
        )
    }

    return (
        <>
            {getTitleContent()}
            <div style={{width: "100%"}}>
            <ExternalPage2 pageUrl={pageLink} />
            </div>
        </>
    )
})

const DetailT1Page = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const isShare = getResultUrlIsShared(searchParams)

    const shareOnClickHd = () => {
        const shareUrl = mkInfoShareUrl(INFO_DETAIL.PAGE_1.SHAREURL)
        sendSharedToApp(shareUrl, INFO_DETAIL.PAGE_1.TITLE, INFO_DETAIL.PAGE_1.DESC)
    }

    return (
        <Detail pageUrl={INFO_DETAIL.PAGE_1.PAGEURL} pageLink={INFO_DETAIL.PAGE_1.LINKURL} isShare={isShare} shareOnClickHd={shareOnClickHd}/>
    )
})

const DetailT2Page = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const isShare = getResultUrlIsShared(searchParams)

    const shareOnClickHd = () => {
        const shareUrl = mkInfoShareUrl(INFO_DETAIL.PAGE_2.SHAREURL)
        sendSharedToApp(shareUrl, INFO_DETAIL.PAGE_2.TITLE, INFO_DETAIL.PAGE_2.DESC)
    }

    return (
        <Detail pageUrl={INFO_DETAIL.PAGE_2.PAGEURL} pageLink={INFO_DETAIL.PAGE_2.LINKURL} isShare={isShare} shareOnClickHd={shareOnClickHd}/>
    )
})

const DetailT3Page = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const isShare = getResultUrlIsShared(searchParams)

    const shareOnClickHd = () => {
        const shareUrl = mkInfoShareUrl(INFO_DETAIL.PAGE_3.SHAREURL)
        sendSharedToApp(shareUrl, INFO_DETAIL.PAGE_3.TITLE, INFO_DETAIL.PAGE_3.DESC)
    }

    return (
        <Detail pageUrl={INFO_DETAIL.PAGE_3.PAGEURL} pageLink={INFO_DETAIL.PAGE_3.LINKURL} isShare={isShare} shareOnClickHd={shareOnClickHd}/>
    )
})

const DetailT4Page = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const isShare = getResultUrlIsShared(searchParams)

    const shareOnClickHd = () => {
        const shareUrl = mkInfoShareUrl(INFO_DETAIL.PAGE_4.SHAREURL)
        sendSharedToApp(shareUrl, INFO_DETAIL.PAGE_4.TITLE, INFO_DETAIL.PAGE_4.DESC)
    }

    return (
        <Detail pageUrl={INFO_DETAIL.PAGE_4.PAGEURL} pageLink={INFO_DETAIL.PAGE_4.LINKURL} isShare={isShare} shareOnClickHd={shareOnClickHd}/>
    )
})

const DetailNormalPage = forwardRef((props = {}, ref) => {
    const [rawData, setRawData] = useSafeState()
    const [displayCollect, setDisplayCollect] = useSafeState(false)
    const [collected, setCollected] = useSafeState(false)

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const isShare = getResultUrlIsShared(searchParams)
    const isInner = getUrlIsInnerJump(searchParams)
    const history = useHistory()

    let infoRef = useRef()

    let { uuid } = useParams() 
    if (uuid.length > 32) {
        uuid = uuid.substring(0, 32)
    }

    const shareOnClickHd = () => {
        if (infoRef?.current) {
            console.log("@@@###--> infoRef?.current = %o", infoRef?.current)
            const shareUrl = mkInfoShareUrl(infoRef?.current?.ShareUrl)
            sendSharedToApp(shareUrl, infoRef?.current?.Title, infoRef?.current?.Description)
        }
    }

    const backOnClickHd = () => {
        if (isInner) {
            history.goBack()
        }else{
            sendBackToApp("")
        }
    }

    const collectOnClickHd = () => {
        console.log("@@@@@@@--> collect clicked!")
        let srcCollected = collected
        
        setTimeout(async () => {
            if (srcCollected) {
                let ret = await infoCollectCancel(uuid)
                if (ret) {
                    setCollected(!srcCollected)
                }
            }else{
                let ret = await infoCollect(uuid)
                if (ret) {
                    setCollected(!srcCollected)
                }
            }
        }, 1)
    }

    const getContent = () => {
        let tmp = BraftEditor.createEditorState(rawData)
        return tmp.toHTML()
    }

    const getTitleContent = () => {
        if (isShare) {
            return undefined
        }

        return (
            <div className='startLine'>
                <BackupTitle 
                backOnClickHd={backOnClickHd} 
                shareOnClickHd={shareOnClickHd} 
                collectOnClickHd={collectOnClickHd}
                displayCollect={displayCollect}
                collected={collected}
                />
            </div>
        )
    }

    useMount(async () => {
        const data = await getWebConfigFromUuid(uuid)
        if (data) {
            setRawData(data)
        }

        const tmpInfo = await qryInfo(uuid)
        if (tmpInfo) {
            infoRef.current = tmpInfo
            console.log("$$$$$$---> tmpInfo = %o", tmpInfo)
        }

        // 收藏按钮处理
        const collectInfo = await infoCollectQry(uuid)
        if (collectInfo) {
            setDisplayCollect(collectInfo.display)
            setCollected(collectInfo.collected)
        }
    })

    return (
        <>
            {getTitleContent()}
            <div className="braft-output-content" dangerouslySetInnerHTML={{__html: getContent()}} />
        </>
    )
})


// ----------------------------------------------------------------------------------
export { DetailPage, DetailT1Page, DetailT2Page, DetailT3Page, DetailT4Page, DetailNormalPage }





