
import { api, SERVER, buildServerUrl } from 'model/api/api';

// -------------------------------------------------------------------------------
export let apiRecognABO = async ( data, extra, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/recogn/ABO", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, extra)
}

export let apiRecognCortisol = async ( data, extra, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/recogn/Cortisol", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, extra)
}

export let apiRecognHistory = async ( data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/recogn/history", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiInfoList = async ( data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/informations/list", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiInfoQry = async ( data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/informations/qry", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiInfoCollect = async (data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/information/collect", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiInfoCollectCancel = async (data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/information/collect/cancel", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiInfoCollectQry = async (data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/information/collect/qry", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiCourseList = async ( data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/course/list", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiCourseQry = async ( data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/course/qry", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiCourseCollect = async ( data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/course/collect", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiCourseCollectCancel = async ( data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/course/collect/cancel", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiCourseCollectQry = async ( data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/course/collect/qry", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiDelRecognResult = async (data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/recogn/history/del", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}

export let apiUserCollectList = async (data, token, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/user/collect/list", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, token)
}


