import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { CapsuleTabs, Tabs } from 'antd-mobile'
import { AOBCourse, CortisolCourse } from 'pages/course/content'
import { useHistory } from 'react-router-dom';
import { StarOutline } from 'antd-mobile-icons'

import { COLOR } from 'component/comm';
import { PRODUCT_TYPE } from 'model/product/product'
import { getCourseList, setLastTabKey, getLastTabKey, courseCollectQry, courseCollect, courseCollectCancel } from 'model/course/course'
import { CourseDetailContent } from 'pages/course/detial'
import { BackIcon, ShareIcon } from 'component/icons'
import { mkInfoShareUrl, getWebConfigFromUuid } from "model/information/info"
import { sendBackToApp, requestTokenFromApp, sendSharedToApp } from 'model/msg/msg'
import { InformationListPage } from 'pages/information/list'

// -------------------------------------------------------------------------------------------
const CoursePage2 = forwardRef((props = {}, ref) => {
    let { needBack } = props
    const [activeTab, setActiveTab] = useSafeState("AOB")
    const history = useHistory();

    const tabOnchangeHd = (key) => {
        console.log("@@@@@@@: key = %o", key)
        setActiveTab(key)
    }

    const backOnClickHd = () => {
        history.goBack()
    }

    const getBackTitle = () => {
        if (needBack) {
            return (
                <div className='startLine'>
                <div className='row_left'>
                    <BackIcon style={{fontSize: "24px"}} onClick={backOnClickHd} />
                    <span style={{marginLeft: "8px", fontSize: "20px", fontWeight: 500}} onClick={backOnClickHd}>返回</span>
                </div>
            </div>
            )
        }

        return undefined
    }

    return (
        <>  
            {getBackTitle()}
            <div className='row_space_small'/>
            <CapsuleTabs activeKey={activeTab} onChange={tabOnchangeHd}>
                <CapsuleTabs.Tab title={PRODUCT_TYPE.description(PRODUCT_TYPE.AOB)}  key={PRODUCT_TYPE.AOB} >
                    <AOBCourse />
                </CapsuleTabs.Tab>

                <CapsuleTabs.Tab title={PRODUCT_TYPE.description(PRODUCT_TYPE.CORTISOL)}  key={PRODUCT_TYPE.CORTISOL} >
                    <CortisolCourse />
                </CapsuleTabs.Tab>
            </CapsuleTabs>
        </>
    )
})


const CourseContent = forwardRef((props = {}, ref) => {
    let { needBack } = props
    const [activeTab, setActiveTab] = useSafeState(undefined)
    const [courseList, setCourseList] = useSafeState([])
    const history = useHistory();

    const tabOnchangeHd = (key) => {
        console.log("@@@@@@@: key = %o", key)
        setActiveTab(key)
    }

    const backOnClickHd = () => {
        history.goBack()
    }

    useMount(async () => {
        const tmpList = await getCourseList()
        if (tmpList) {
            
            if (tmpList?.length > 0) {
                setActiveTab(tmpList[0]?.Uuid)
                setCourseList(tmpList)
            }
        }
    })

    const getBackTitle = () => {
        if (needBack) {
            return (
                <div className='startLine'>
                <div className='row_left'>
                    <BackIcon style={{fontSize: "24px"}} onClick={backOnClickHd} />
                    <span style={{marginLeft: "8px", fontSize: "20px", fontWeight: 500}} onClick={backOnClickHd}>返回</span>
                </div>
            </div>
            )
        }

        return undefined
    }

    const shareOnClickHd = (record) => {
        if (record) {
            const shareUrl = mkInfoShareUrl(record?.AccessUrl)
            sendSharedToApp(shareUrl, record?.Title, record?.Description)
        }
    }

    const getTabContent = () => {
        if (courseList) {
            let displayContent = courseList?.map((value, index) => {
                return (
                    <CapsuleTabs.Tab title={value?.DisplayCategory} key={value?.Uuid}>
                        {/* <div className='startLine'>
                            <div className='row_right'>
                                <div onClick={() => shareOnClickHd(value)} style={{marginRight: "16px"}}>
                                    <ShareIcon style={{fontSize: "20px"}}/>
                                </div>
                            </div>
                        </div> */}
                        <CollectCard uuid={value?.Uuid}/>
                        <CourseDetailContent uuid={value?.Uuid} key={index}/>
                    </CapsuleTabs.Tab>
                )
            })

            return displayContent
        }

        return undefined
    }

    return (
        <>  
            {getBackTitle()}
            <div className='row_space_small'/>
            <CapsuleTabs activeKey={activeTab} onChange={tabOnchangeHd}>
                {getTabContent()}
            </CapsuleTabs>
        </>
    )
})

const CoursePage = forwardRef((props = {}, ref) => {
    const [activeTab, setActiveTab] = useSafeState(getLastTabKey())

    const mkTitle = (title) => {
        return (
            <span style={{fontSize: "26px", fontWeight: 500}}>{title}</span>
        )
    }

    const tabChangeHd = (key) => {
        setLastTabKey(key)
        setActiveTab(key)
    }

    return (
        <>  
            <div className='row_space_small'/>
            <div className='ho-tabs'>
            <Tabs stretch={false}
                onChange={tabChangeHd}
                activeKey={activeTab}
                activeLineMode='fixed'
                style={{
                    '--fixed-active-line-width': '24px',
                    '--active-line-height': '6px', 
                    }}
            >
                <Tabs.Tab title={mkTitle('教程')} key='course' >
                    <CourseContent />
                </Tabs.Tab>
                <Tabs.Tab title={mkTitle('资讯')} key='info' >
                    <InformationListPage innerPage={true} />
                </Tabs.Tab>
            </Tabs>
            </div>
        </>
    )
})

const CourseHasBackPage = forwardRef((props = {}, ref) => {

    return (
        <CourseContent needBack={true}/>
    )
}) 


const CollectCard = forwardRef((props = {}, ref) => {
    let { uuid } = props
    const [displayCollect, setDisplayCollect] = useSafeState(false)
    const [collected, setCollected] = useSafeState(false)

    useMount(async () => {
        const collectInfo = await courseCollectQry(uuid)
        if (collectInfo) {
            setDisplayCollect(collectInfo.display)
            setCollected(collectInfo.collected)
        }
    })

    const collectOnClickHd = () => {
        console.log("@@@@@@@--> collect clicked!")
        let srcCollected = collected
        
        setTimeout(async () => {
            if (srcCollected) {
                let ret = await courseCollectCancel(uuid)
                if (ret) {
                    setCollected(!srcCollected)
                }
            }else{
                let ret = await courseCollect(uuid)
                if (ret) {
                    setCollected(!srcCollected)
                }
            }
        }, 1)
    }

    const getCollectDisplay = () => {
        if (displayCollect) {
            let colorStr = ""
            if (collected) {
                colorStr = "var(--adm-color-primary)"
            }
            return (
                <div className='row_right' >
                    <StarOutline style={{fontSize: "28px", marginBottom: "0px"}} color={colorStr} onClick={collectOnClickHd} />
                </div>
            )
        }

        return undefined
    }

    return (
        <>
            {getCollectDisplay()}
        </>
    )
})


// -------------------------------------------------------------------------------------------
export { CoursePage, CourseHasBackPage, CollectCard }


