import { apiRecognABO, apiRecognCortisol, apiRecognHistory, apiDelRecognResult } from 'model/api/recogn'
import { hh_log } from 'model/util/logic'
import { getUserToken } from 'model/user/user'
import { eventReg, eventUnreg, eventPublish } from "model/util/logic"
import { BASE_URL } from 'model/util/comm'

// -----------------------------------------------------------------------
let RECOGN_EVENT = {
    HISTORY_CHANGED: "history-changed", 
}




let mntn = {
    historyList: [], 
    notfiyMap: {}, 
    underApproval: false, 
}


// -----------------------------------------------------------------------
const recognABORequest = async (dataURL) => {
    let req = {ImgData: dataURL}

    let result = {HasResult: false}
    try {
        let rsp = await apiRecognABO(req)
        if (rsp?.Status !== 0) {
            if (rsp?.Status === 1002) {
                result.ResultUrl = rsp?.ResultUrl
            }
            hh_log(`recognABORequest: apiRecognABO request failed! Status = ${rsp?.Status}, StatusMsg = ${rsp?.StatusMsg}`)
            return result
        }
        result.HasResult = true
        result.BloodGroup = rsp?.BloodGroup
        result.ResultUrl = rsp?.ResultUrl
        result.RecognResults = rsp?.RecognResults
        return result
    }catch(error){
        hh_log(`recognABORequest: apiRecognABO request failed! error = ${error}`)
    }

    return result
}

const recognCortisolRequest = async (dataURL) => {
    let req = {ImgData: dataURL}

    let result = {HasResult: false}
    try {
        let rsp = await apiRecognCortisol(req)
        if (rsp?.Status !== 0) {
            if (rsp?.Status === 1002) {
                result.ResultUrl = rsp?.ResultUrl
            }

            hh_log(`recognCortisolRequest: apiRecognCortisol request failed! Status = ${rsp?.Status}, StatusMsg = ${rsp?.StatusMsg}`)
            return result
        }
        result.HasResult = true
        result.ResultUrl = rsp?.ResultUrl
        result.CortisolValue = rsp?.CortisolValue
        result.RecognResults = rsp?.RecognResults
        return result
    }catch(error){
        hh_log(`recognCortisolRequest: apiRecognCortisol request failed! error = ${error}`)
    }

    return result
}

const recognHistoryRequest = (underApproval) => {
    const token = getUserToken()
    if (token === "") {
        // 没有token信息直接返回
        return
    }

    let req = {PageSize: 999, PageNum: 1}

    if (underApproval) {
        req.UnderApproval = true
    }

    setTimeout(async () => {
        try {
            let rsp = await apiRecognHistory(req, token)
            if (rsp?.Status !== 0) {
                hh_log(`recognHistoryRequest: apiRecognHistory request failed! Status = ${rsp?.Status}, StatusMsg = ${rsp?.StatusMsg}`)
                return
            }
            // 保存历史记录信息
            let tmpList = []
            for (let item of rsp?.RecordList) {
                let result = {
                    Id: item?.Id, 
                    UserId: item?.UserId, 
                    ProductType: item?.ProductType, 
                    OpTime: item?.OpTime, 
                    ProductTitle: item?.ProductTitle, 
                    DisplayTitle: item?.DisplayTitle, 
                    ResultValue: item?.ResultValue, 
                    ResultDisplayUrl: item?.ResultDisplayUrl, 
                }
                tmpList.push(result)
            }
            mntn.historyList = tmpList
            // 发布历史记录变化事件
            recognEventPublish(RECOGN_EVENT.HISTORY_CHANGED, mntn.historyList)
        }catch(error) {
            hh_log("recognHistoryRequest: apiRecognHistory error = %o", error)
        }
    })
}

const delResultRequest = (underApproval, recordId) => {
    const token = getUserToken()
    if (token === "") {
        // 没有token信息直接返回
        return
    }

    console.log("#####-->: underApproval = %o, recordId = %o", underApproval, recordId)

    setTimeout(async () => {
        let ids = []
        ids.push(recordId)
        let rsp = await apiDelRecognResult({Ids: ids}, token)
            if (rsp?.Status !== 0) {
                hh_log(`delResultRequest: delResultRequest request failed! Status = ${rsp?.Status}, StatusMsg = ${rsp?.StatusMsg}`)
                return
            }
            // 成功的话，刷新历史记录
            recognHistoryRequest(underApproval)
    }, 0)

    

}


// -----------------------------------------------------------------------
const mkResultShareUrl = (url, opTime=undefined) => {
    let result = url + "?share=yes"
    if (opTime) {
        result = result + "&optime=" + opTime
    }
    return result
}

const getResultUrlHasTitle = (searchParams) => {
    const title = searchParams.get("title")
    let hasTitle = true
    if (title === "no") {
        hasTitle = false
    }
    return hasTitle
}

const getResultUrlIsShared = (searchParams) => {
    const share = searchParams.get("share")
    let isShare = false
    if (share === "yes") {
        isShare = true
    }
    return isShare
}

const getResultUrlOptime = (searchParams) => {
    return searchParams.get("optime")
}

const getRecognHistoryList = () => {
    return mntn.historyList
}

const mkHistoryDetailUrl = (srcUrl, optime) => {
    let baseUrl = srcUrl.replace(BASE_URL.ONLINE, "")
    return baseUrl + "?optime=" + optime
}

const mkLatestDetailUrl = (srcUrl, optime) => {
    let baseUrl = srcUrl.replace(BASE_URL.ONLINE, "")
    return baseUrl + "?optime=" + optime + "&share=yes" + "&title=no"
}

const getUrlIsUnderApproval = (searchParams) => {
    const underApproval = searchParams.get("underApproval")
    let isUnder = false
    if (underApproval === "true") {
        isUnder = true
    }
    mntn.underApproval = isUnder
    return isUnder
}

const getUnderApproval = () => {
    return mntn.underApproval
}


// -----------------------------------------------------------------------
// 事件发布
const recognEventReg = (key, eventList, notifyCb) => {
    eventReg(mntn, key, eventList, notifyCb)
}

const recognEventUnreg = (key) => {
    eventUnreg(mntn, key)
}

const recognEventPublish = (event, msg) => {
    eventPublish(mntn, event, msg)
}

// -----------------------------------------------------------------------
export { RECOGN_EVENT }

export { recognABORequest, recognCortisolRequest }

export { mkResultShareUrl, getResultUrlHasTitle, getResultUrlIsShared, getResultUrlOptime, getUrlIsUnderApproval, getUnderApproval }

export { getRecognHistoryList, recognEventReg, recognEventUnreg, recognHistoryRequest, mkHistoryDetailUrl, mkLatestDetailUrl, delResultRequest }

