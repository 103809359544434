import { apiGet } from "model/api/api"
import { apiInfoList, apiInfoQry, apiInfoCollect, apiInfoCollectCancel, apiInfoCollectQry } from "model/api/recogn"
import { getUserToken } from "model/user/user"
import { RSP_STATUS } from "model/msg/msg"
import { BASE_URL } from 'model/util/comm'


//-----------------------------------------------------------------------------------------------
let INFO_DETAIL = {
    PAGE_1: {
        TITLE: "院企合作共建科研基地", 
        DESC: "郑州方欣生物与黄河科技学院附属医院签约共建科研基地，推动科研创新", 
        PICURL: "https://hhmobile.angliancd.com/res/information/t1.jpg", 
        LINKURL: "https://hhmobile.angliancd.com/res/information/html/%E5%96%9C%E8%AE%AF%EF%BC%81%E6%B7%B1%E5%8C%96%E9%99%A2%E4%BC%81%E5%90%88%E4%BD%9C%20%E6%8E%A8%E5%8A%A8%E7%A7%91%E7%A0%94%E5%88%9B%E6%96%B0--%E9%83%91%E5%B7%9E%E6%96%B9%E6%AC%A3%E7%94%9F%E7%89%A9%E4%B8%8E%E9%BB%84%E6%B2%B3%E7%A7%91%E6%8A%80%E5%AD%A6%E9%99%A2%E9%99%84%E5%B1%9E%E5%8C%BB%E9%99%A2%E7%AD%BE%E7%BA%A6%E5%85%B1%E5%BB%BA%E7%A7%91%E7%A0%94%E5%9F%BA%E5%9C%B0.html", 
        PAGEURL: "https://hhmobile.angliancd.com/information/detail/t1", 
        SHAREURL: "/information/detail/t1", 
    }, 

    PAGE_2: {
        TITLE: "2023CMEF（春）精彩回顾", 
        DESC: "方欣生物自测产品引关注", 
        PICURL: "https://hhmobile.angliancd.com/res/information/t2.jpg", 
        LINKURL: "https://hhmobile.angliancd.com/res/information/html/2023CMEF%EF%BC%88%E6%98%A5%EF%BC%89%E7%B2%BE%E5%BD%A9%E5%9B%9E%E9%A1%BE%EF%BD%9C%E6%96%B9%E6%AC%A3%E7%94%9F%E7%89%A9%E8%87%AA%E6%B5%8B%E4%BA%A7%E5%93%81%E5%BC%95%E5%85%B3%E6%B3%A8%EF%BC%81.html", 
        PAGEURL: "https://hhmobile.angliancd.com/information/detail/t2", 
        SHAREURL: "/information/detail/t2", 
    }, 

    PAGE_3: {
        TITLE: "全民唾液丙肝筛查", 
        DESC: "全国首次！14省市联合举办唾液丙肝检测筛查活动", 
        PICURL: "https://hhmobile.angliancd.com/res/information/t3.png", 
        LINKURL: "https://hhmobile.angliancd.com/res/information/html/%E5%85%A8%E5%9B%BD%E9%A6%96%E6%AC%A1%EF%BC%8114%E7%9C%81%E5%B8%82%E8%81%94%E5%90%88%E4%B8%BE%E5%8A%9E%E5%94%BE%E6%B6%B2%E4%B8%99%E8%82%9D%E6%A3%80%E6%B5%8B%E7%AD%9B%E6%9F%A5%E6%B4%BB%E5%8A%A8.html", 
        PAGEURL: "https://hhmobile.angliancd.com/information/detail/t3", 
        SHAREURL: "/information/detail/t3", 
    }, 

    PAGE_4: {
        TITLE: "质量体系，生产规范", 
        DESC: "加强质量体系培训，严抓生产规范", 
        PICURL: "https://hhmobile.angliancd.com/res/information/t4.jpg", 
        LINKURL: "https://hhmobile.angliancd.com/res/information/html/%E5%8A%A0%E5%BC%BA%E8%B4%A8%E9%87%8F%E4%BD%93%E7%B3%BB%E5%9F%B9%E8%AE%AD%EF%BC%8C%E4%B8%A5%E6%8A%93%E7%94%9F%E4%BA%A7%E8%A7%84%E8%8C%83.html", 
        PAGEURL: "https://hhmobile.angliancd.com/information/detail/t4", 
        SHAREURL: "/information/detail/t4", 
    }, 
}

//-----------------------------------------------------------------------------------------------
const mkInfoShareUrl = (url) => {
    let result = url + "?share=yes"
    return result
}

const mkInnerJumpUrl = (url) => {
    let baseUrl = url.replace(BASE_URL.ONLINE, "")
    let result = baseUrl + "?innerJump=yes"
    return result
}

const getWebConfigFromUuid = async (uuid) => {
    let dstUrl = "https://hhmobile.angliancd.com/res/web-config/" + uuid + ".json"
    try {
        let rsp = await apiGet(dstUrl)
        return rsp
    }catch(error){
        console.log("getWebConfigFromUuid: request failed! uuid = %o", uuid)
    }
    return undefined
}

const getInfoList = async () => {
    const token = getUserToken()
    try {
        let rsp = await apiInfoList({}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            return rsp?.RecordList
        }else{
            console.log("getInfoList: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("getInfoList: failed! error = %o", error)
    }
    return undefined
}

const qryInfo = async (uuid) => {
    const token = getUserToken()
    try {
        let rsp = await apiInfoQry({Uuid: uuid}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            return {Title: rsp?.Title, Description: rsp?.Description, ShareUrl: rsp?.ShareUrl}
        }else{
            console.log("qryInfo: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("qryInfo: failed! error = %o", error)
    }
    return undefined
}

const infoCollectQry = async (uuid) => {
    let result = {
        display: false, 
        collected: false, 
    }

    const token = getUserToken()
    if (token === "") {
        return result
    }

    try {
        let rsp = await apiInfoCollectQry({Uuid: uuid}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            result.display = true
            result.collected = rsp?.Collected
        }else{
            console.log("infoCollectQry: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("infoCollectQry: failed! error = %o", error)
    }
    return result
}

const infoCollect = async (uuid) => {
    let result = false
    const token = getUserToken()
    try {
        let rsp = await apiInfoCollect({Uuid: uuid}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            result = true
        }else{
            console.log("infoCollect: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("infoCollect: failed! error = %o", error)
    }

    return result
}

const infoCollectCancel = async (uuid) => {
    let result = false
    const token = getUserToken()
    try {
        let rsp = await apiInfoCollectCancel({Uuid: uuid}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            result = true
        }else{
            console.log("infoCollectCancel: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("infoCollectCancel: failed! error = %o", error)
    }

    return result
}

const getUrlIsInnerJump = (searchParams) => {
    const inner = searchParams.get("innerJump")
    let isInner = false
    if (inner === "yes") {
        isInner = true
    }
    return isInner
}


//-----------------------------------------------------------------------------------------------
export { INFO_DETAIL, mkInfoShareUrl, getWebConfigFromUuid, getInfoList, qryInfo, mkInnerJumpUrl, getUrlIsInnerJump }
export { infoCollectQry, infoCollect, infoCollectCancel }

