import React, { RefObject, forwardRef, useRef } from 'react'
import { useSafeState, useMount, useUpdate, useUnmount } from 'ahooks';
import { Grid, Button, Space, Modal, Radio, Tabs, Image  } from 'antd-mobile'
import { COLOR } from 'component/comm';
import { BottomNavBar } from './navBar'
import { BackIcon } from 'component/icons'
import { sendBackToApp, requestTokenFromApp } from 'model/msg/msg'
import { userEventReg, userEventUnreg, USER_EVENT, setUserToken, getUserToken } from 'model/user/user'

const PAGE = 'page-home'
// -----------------------------------------------------------------------
const HomePage = forwardRef((props = {}, ref) => {
    return (
        <div className="app">
            <div className='body'>
                <span>首页内容</span>
            </div>
            <BottomNavBar />
        </div>
    )
})


const ContentTitle = forwardRef((props = {}, ref) => {
    let { title, titleColor, url } = props

    const backOnClickHd = () => {
        sendBackToApp(url)
    }

    return (
        <>
            <div className='startLine'>
                <div className='row_left'>
                    <div onClick={backOnClickHd}>
                        <BackIcon style={{fontSize: "24px"}} />
                    </div>
                </div>
            </div>

            <div className='row_space_middle'/>

            <div className='row_space_middle'/>

            <div className='row_center'>
                <span style={{fontSize: "20px", fontWeight: 500, color: titleColor}}>页面开发中，敬请期待</span>
            </div>

            <div className='row_space_big'/>
            <div className='row_space_middle'/>
            <div className='row_space_middle'/>
        </>
    )
})

const DevelopmentPage = forwardRef((props = {}, ref) => {
    let {match} = props

    const url = match?.url

    const rfCourseClickHd = () => {
        requestTokenFromApp(url)
    }

    // const userEventHd = (event, data) => {
    //     if (event === USER_EVENT.TOKEN_CHANGED) {
    //         Modal.alert(
    //             {
    //                 title: data, 
    //             }
    //         )
    //     }
    // }

    const testFunc = () => {
        // if (window.setUserToken) {
        //     window.setUserToken("558888000")
        // }
        const token = getUserToken()
        setUserToken(token)
    }

    useMount(() => {
        // userEventReg(`${PAGE}-Development`, [USER_EVENT.TOKEN_CHANGED], userEventHd)
    })

    useUnmount(() => {
        // userEventUnreg(`${PAGE}-Development`)
    })

    return (
        <>
            <ContentTitle title="" titleColor={COLOR.GRAY_13} url={url}/>
                <div className='resultBG2'>
                    <div className='pic' onClick={testFunc}>
                        <Image src={process.env.PUBLIC_URL + "/images/noResult.png"} />
                    </div>
                </div>

                <div style={{width: 100, height: "240px"}}/>
        </>
    )
})






// -----------------------------------------------------------------------
export { HomePage, DevelopmentPage }
