import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'

import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { DotLoading } from 'antd-mobile'
import { StarOutline } from 'antd-mobile-icons'

import { BackIcon, ShareIcon } from 'component/icons'

// ---------------------------------------------------------------------------------------
const BackupTitle = forwardRef((props = {}, ref) => {
    let {backOnClickHd, title, shareOnClickHd, noShare, collectOnClickHd, displayCollect, collected} = props

    const getShareDisplay = () => {
        if (noShare) {
            return undefined
        }

        return (
            <div onClick={shareOnClickHd} style={{marginRight: "16px"}}>
                <ShareIcon style={{fontSize: "20px"}}/>
            </div>
        )
    }

    const getCollectDisplay = () => {
        if (displayCollect) {
            let colorStr = ""
            if (collected) {
                colorStr = "var(--adm-color-primary)"
            }
            return (
                <div onClick={collectOnClickHd} style={{marginRight: "16px"}}>
                    <StarOutline style={{fontSize: "28px", marginBottom: "6px"}} color={colorStr}/>
                </div>
            )
        }

        return undefined
    }

    return (
        <div className='startLine'>
            <div className='row_left'>
                <div style={{display: "flex", flex: 1}}>
                    <div onClick={backOnClickHd}>
                        <BackIcon style={{fontSize: "24px"}} />
                    </div>     
                    <span style={{marginLeft: "8px", fontSize: "20px", fontWeight: 500}}>{title}</span>
                </div>
                {getCollectDisplay()}
                {getShareDisplay()}
            </div>
        </div>
    )
})

const LoadingHint = forwardRef((props = {}, ref) => {
    let { fontSize } = props

    let tmpFontSize = "64px"
    if (fontSize) {
        tmpFontSize = fontSize
    }

    return (
        <div style={{display: "flex", justifyContent: "center", fontSize: tmpFontSize, margin: "32px 0px 32px 0px"}}>
            <DotLoading />
        </div>
    )
})


// ---------------------------------------------------------------------------------------
const useWindowSize = () => {
    const [windowSize, setWindowSize] = useSafeState({
        width: window.innerWidth, 
        height: window.innerHeight, 
    })

    useEffect(() => {
        const updateSize = () => {
            setWindowSize({
                width: window.innerWidth, 
                height: window.innerHeight, 
            })
        }

        window.addEventListener('resize', updateSize)
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    return windowSize
}



// ---------------------------------------------------------------------------------------
export { BackupTitle, LoadingHint }

export { useWindowSize }





