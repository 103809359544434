import { eventReg, eventUnreg, eventPublish } from "model/util/logic"
import { apiUserCollectList } from 'model/api/recogn'
import { RSP_STATUS } from "model/msg/msg"

// -----------------------------------------------------------------------------------------
let USER_EVENT = {
    TOKEN_CHANGED: "token-changed", 
}


let mntn = {
    userToken: "", 
    notfiyMap: {}, 
}
// -----------------------------------------------------------------------------------------
const setUserToken = (token) => {
    console.log("@@@@@@@@@---> : setUserToken: token = %o", token)
    mntn.userToken = token
    userEventPublish(USER_EVENT.TOKEN_CHANGED, token)
}

const getUserToken = () => {
    return mntn.userToken
}

const userCollectList = async () => {
    let result = undefined

    const token = getUserToken()
    if (token === "") {
        return result
    }

    try {
        let rsp = await apiUserCollectList({}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            result = {
                InfoList: rsp?.InfoList, 
                CourseList: rsp?.CourseList, 
            }
        }else{
            console.log("userCollectList: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("userCollectList: failed! error = %o", error)
    }
    return result
}

// 事件发布
const userEventReg = (key, eventList, notifyCb) => {
    eventReg(mntn, key, eventList, notifyCb)
}

const userEventUnreg = (key) => {
    eventUnreg(mntn, key)
}

const userEventPublish = (event, msg) => {
    eventPublish(mntn, event, msg)
}


// -----------------------------------------------------------------------------------------
export { getUserToken, setUserToken, userCollectList, userEventReg, userEventUnreg, USER_EVENT }


