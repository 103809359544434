import { apiGet } from "model/api/api"
import { apiCourseList, apiCourseQry, apiCourseCollect, apiCourseCollectCancel, apiCourseCollectQry } from "model/api/recogn"
import { getUserToken } from "model/user/user"
import { RSP_STATUS } from "model/msg/msg"

//-----------------------------------------------------------------------------------------------
let mntn = {
    lastTabKey: "course", 
    collectTabKey: "info"
}

//-----------------------------------------------------------------------------------------------
const getCourseList = async () => {
    const token = getUserToken()
    try {
        let rsp = await apiCourseList({}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            return rsp?.RecordList
        }else{
            console.log("getCourseList: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("getCourseList: failed! error = %o", error)
    }
    return undefined
}

const qryCourse = async (uuid) => {
    const token = getUserToken()
    try {
        let rsp = await apiCourseQry({Uuid: uuid}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            return {
                DisplayCategory: rsp?.DisplayCategory, 
                Title: rsp?.Title, 
                TitlePicUrl: rsp?.TitlePicUrl, 
                Description: rsp?.Description, 
                ShareUrl: rsp?.ShareUrl, 
            }
        }else{
            console.log("qryCourse: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("qryCourse: failed! error = %o", error)
    }
    return undefined
}

const setLastTabKey = (key) => {
    mntn.lastTabKey = key
}

const getLastTabKey = () => {
    return mntn.lastTabKey
}

const setCollectTabKey = (key) => {
    mntn.collectTabKey = key
}

const getCollectTabKey = () => {
    return mntn.collectTabKey
}

const courseCollectQry = async (uuid) => {
    let result = {
        display: false, 
        collected: false, 
    }

    const token = getUserToken()
    if (token === "") {
        return result
    }

    try {
        let rsp = await apiCourseCollectQry({Uuid: uuid}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            result.display = true
            result.collected = rsp?.Collected
        }else{
            console.log("courseCollectQry: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("courseCollectQry: failed! error = %o", error)
    }
    return result
}

const courseCollect = async (uuid) => {
    let result = false
    const token = getUserToken()
    try {
        let rsp = await apiCourseCollect({Uuid: uuid}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            result = true
        }else{
            console.log("courseCollect: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("courseCollect: failed! error = %o", error)
    }

    return result
}

const courseCollectCancel = async (uuid) => {
    let result = false
    const token = getUserToken()
    try {
        let rsp = await apiCourseCollectCancel({Uuid: uuid}, token)
        if (rsp?.Status === RSP_STATUS.SUCCESS) {
            result = true
        }else{
            console.log("courseCollectCancel: status = %o, statusMsg = %o", rsp?.Status, rsp?.StatusMst)
        }
    }catch(error) {
        console.log("courseCollectCancel: failed! error = %o", error)
    }

    return result
}



//-----------------------------------------------------------------------------------------------
export { getCourseList, qryCourse, setLastTabKey, getLastTabKey, setCollectTabKey, getCollectTabKey }
export { courseCollectQry, courseCollect, courseCollectCancel }



